/** @jsx jsx */
import { jsx, Box, Container } from "theme-ui"

import { Layout } from "@giraldomac/gatsby-theme-wvumedicine/src/components/layout"
import notFound from "@giraldomac/gatsby-theme-wvumedicine/src/images/not-found.svg"

const NotFoundPage = () => (
  <Layout>
    <Container sx={{ textAlign: "center", px: 3 }}>
      <Box sx={{ py: 4 }}>
        <h1>NOT FOUND</h1>

        <p>
          The page you are looking for doesn&#39;t exist... Please use the links
          above to navigate to the page you want.
        </p>
        <br />
        <Box style={{ px: [3, 4, 5], py: [2, 3], width: "full" }}>
          <img src={notFound} alt="Not Found" width="100%" />
        </Box>
      </Box>
    </Container>
  </Layout>
)

export default NotFoundPage
